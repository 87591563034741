import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import CONFIG from '../config.json';

const Technology = () => {
  useEffect(() => {
    document.title = `Tecnología Prime ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col xs={12} md={6}>
          <Image src='/img/news/technology-banner.png' fluid={true} />
        </Col>
        <Col xs={12} md={6}>
          <h2 className='text-uppercase'>Tecnología Prime</h2>
          <p>
            Tenemos el menor índice de fallas del mercado debido a que
            fabricamos nuestros equipos con:
          </p>
          <ul>
            <li>Los mejores componentes</li>
            <li>En la mejor fábrica</li>
            <li>Con los más estrictos controles de calidad</li>
          </ul>
        </Col>
      </Row>
      <p className='my-4 h4 text-center text-primary text-uppercase'>
        Aires Prime es la marca líder con la mejor propuesta para el consumidor
        final
      </p>
      <Row>
        <Col xs={12} md={6}>
          <p className='text-uppercase fw-bold'>Diseño "C" evaporador</p>
          <p>
            Aminora el trabajo de intercambio de calor, por su diseño envolvente
            que abarca mayor área para el flujo de aire.
            <br />
            Diseño de fin para máximo intercambio de calor.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className='text-uppercase fw-bold'>
            Flaps o ventilas direccionales
          </p>
          <p>
            Pequeñas flaps integradas, movibles y de fácil mantenimiento
            <br />
            Flaps con ranuras para prevenir goteos.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className='text-uppercase fw-bold'>Caja de controles eléctricos</p>
          <p>
            Diseño optimizado de caja de control eléctrico. No es necesario
            desarmar muchos componentes, facilitando el mantenimiento
            post-venta.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <p className='text-uppercase fw-bold'>Función turbo</p>
          <p>
            Con la función Turbo se logra entre un 5% a un 10% de mayor rapidez
            en enfriamiento.
          </p>
        </Col>
        <Col className='d-flex flex-column' xs={12} md={6}>
          <p className='text-uppercase fw-bold'>
            Flujo de aire multidireccional
          </p>
          <Image
            className='mb-3'
            src='/img/technology/air-flow.jpg'
            fluid={true}
          />
        </Col>
        <Col className='d-flex flex-column' xs={12} md={6}>
          <p className='text-uppercase fw-bold'>Auto limpieza</p>
          <Image
            className='mb-3'
            src='/img/technology/cleaning.png'
            fluid={true}
          />
        </Col>
        <Col className='d-flex flex-column' xs={12} md={6}>
          <p className='text-uppercase fw-bold'>Modo: dormir</p>
          <Image
            className='mb-3'
            src='/img/technology/sleep-1.png'
            fluid={true}
          />
          <Image
            className='mb-3'
            src='/img/technology/sleep-2.png'
            fluid={true}
          />
        </Col>
      </Row>
      {/* More information idk pls I want to finish this thing :( */}
      <p className='h4 text-center text-uppercase my-3 text-primary'>
        Funciones estándar
      </p>
      <div className='d-flex w-100 justify-content-center'>
        <Image src='/img/technology/standard-functions.jpg' fluid={true} />
      </div>
      <p className='h4 text-center text-uppercase my-3 text-primary'>
        Funciones PLUS
      </p>
      <div className='d-flex w-100 justify-content-center'>
        <Image src='/img/technology/plus-functions.jpg' fluid={true} />
      </div>
    </Container>
  );
};

export default Technology;

import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <Container className='d-none d-md-block pb-2'>
      <Row>
        <Col
          className='d-flex justify-content-center align-items-center flex-column'
          xs={12}
          md={6}
          xl={2}
        >
          <Link to='/'>
            <Image src='/img/logo.png' width={200} />
          </Link>
          <div className='d-flex social-media-header'>
            <a href='#'>
              <Image src='/img/social/facebook.png' height={32} width={32} />
            </a>
            <a href='#'>
              <Image src='/img/social/instagram.png' height={32} width={32} />
            </a>
          </div>
        </Col>
        <Col className='d-none d-xl-flex align-items-center'>
          <Image src='/img/banner.jpg' fluid={true} />
        </Col>
        <Col className='text-center' xs={12} md={6} xl={2}>
          <p className='mb-0'>Informes y ventas</p>
          <a className='d-block' href='tel:+523315662500'>
            <FontAwesomeIcon icon={faPhone} />
            33 1566 2500
          </a>
          <a className='d-block mb-2' href='https://wa.me/3315662500'>
            <Image src='/img/social/whatsapp.png' height={30} width={30} />
            33 1566 2500
          </a>
          <a className='d-block' href='tel:+523314431856'>
            <FontAwesomeIcon icon={faPhone} />
            33 1443 1856
          </a>
          <a className='d-block' href='https://wa.me/3314431856'>
            <Image src='/img/social/whatsapp.png' height={30} width={30} />
            33 1443 1856
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;

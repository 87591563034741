import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  Navbar as BoostrapNavbar,
  Container,
  Nav,
  Image,
} from 'react-bootstrap';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeRoute, setActiveRoute] = useState('home');
  const [expandedNavbar, setExpandedNavbar] = useState(false);

  // Update active route in navbar
  useEffect(() => {
    const path = location.pathname.split('/')[1];

    if (path != null) {
      switch (path) {
        case '':
          setActiveRoute('home');
          break;
        case 'nosotros':
        case 'elige-bien':
        case 'informacion':
        case 'noticias':
        case 'tecnologia':
          setActiveRoute('us');
          break;
        case 'productos':
        case 'producto':
          setActiveRoute('products');
          break;
        case 'contacto':
          setActiveRoute('contact');
          break;
        default:
          setActiveRoute('home');
          break;
      }
    }
  }, [location]);

  const changeRoute = useCallback(
    (event, location) => {
      event.preventDefault();

      navigate(location);

      if (expandedNavbar) {
        setExpandedNavbar(false);
      }
    },
    [expandedNavbar, navigate]
  );

  return (
    <>
      {/* Desktop navbar */}
      <Container className='d-none d-md-block'>
        <BoostrapNavbar className='py-0' bg='light' expand='md'>
          <Nav className='me-auto'>
            <Nav.Link
              className='p-3 text-uppercase'
              active={activeRoute === 'home'}
              href='/'
              onClick={(event) => changeRoute(event, '/')}
            >
              Inicio
            </Nav.Link>
            <Nav.Link
              className='p-3 text-uppercase'
              active={activeRoute === 'us'}
              href='/nosotros'
              onClick={(event) => changeRoute(event, '/nosotros')}
            >
              Empresa
            </Nav.Link>
            <Nav.Link
              className='p-3 text-uppercase'
              active={activeRoute === 'products'}
              href='/productos'
              onClick={(event) => changeRoute(event, '/productos')}
            >
              Productos
            </Nav.Link>
            <Nav.Link
              className='p-3 text-uppercase'
              active={activeRoute === 'contact'}
              href='/contacto'
              onClick={(event) => changeRoute(event, '/contacto')}
            >
              Contacto
            </Nav.Link>
          </Nav>
        </BoostrapNavbar>
      </Container>
      {/* Mobile navbar */}
      <BoostrapNavbar
        className='d-flex d-md-none'
        bg='light'
        expand='lg'
        expanded={expandedNavbar}
      >
        <Container>
          <BoostrapNavbar.Brand>
            <Link to='/'>
              <Image src='/img/logo.png' />
            </Link>
            <p className='mt-2'>
              <a href='tel:+523315662500'>
                <FontAwesomeIcon icon={faPhone} />
                33 1566 2500
              </a>
            </p>
            <p>
              <a href='tel:+523314431856'>
                <FontAwesomeIcon icon={faPhone} />
                33 1443 1856
              </a>
            </p>
          </BoostrapNavbar.Brand>
          <BoostrapNavbar.Toggle
            aria-controls='basic-navbar-nav'
            onClick={() => setExpandedNavbar(!expandedNavbar)}
          />
          <BoostrapNavbar.Collapse>
            <Nav className='me-auto'>
              <Nav.Link
                className='text-uppercase text-center'
                active={activeRoute === 'home'}
                href='/'
                onClick={(event) => changeRoute(event, '/')}
              >
                Inicio
              </Nav.Link>
              <Nav.Link
                className='text-uppercase text-center'
                active={activeRoute === 'us'}
                href='/nosotros'
                onClick={(event) => changeRoute(event, '/nosotros')}
              >
                Empresa
              </Nav.Link>
              <Nav.Link
                className='text-uppercase text-center'
                active={activeRoute === 'products'}
                href='/productos'
                onClick={(event) => changeRoute(event, '/productos')}
              >
                Productos
              </Nav.Link>
              <Nav.Link
                className='text-uppercase text-center'
                active={activeRoute === 'contact'}
                href='/contacto'
                onClick={(event) => changeRoute(event, '/contacto')}
              >
                Contacto
              </Nav.Link>
            </Nav>
          </BoostrapNavbar.Collapse>
        </Container>
      </BoostrapNavbar>
    </>
  );
};

export default Navbar;

import { useEffect } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CONFIG from '../config.json';

const Us = () => {
  useEffect(() => {
    document.title = `Empresa ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <>
      <Container className='text-center py-5'>
        <Row>
          <Col xs={12} md={4}>
            <Link className='text-black text-decoration-none' to='/elige-bien'>
              <Image className='mb-3' src='/img/us/us-1.png' fluid={true} />
              <p className='h4 no-bold text-uppercase'>
                Infórmate y elige el mejor
              </p>
            </Link>
          </Col>
          <Col xs={12} md={4}>
            <Link className='text-black text-decoration-none' to='/informacion'>
              <Image className='mb-3' src='/img/us/us-2.png' fluid={true} />
              <p className='h4 no-bold text-uppercase'>
                Información de la compañía
              </p>
            </Link>
          </Col>
          <Col xs={12} md={4}>
            <Link className='text-black text-decoration-none' to='/noticias'>
              <Image className='mb-3' src='/img/us/us-3.png' fluid={true} />
              <p className='h4 no-bold text-uppercase'>
                Noticias y comunicados
              </p>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Us;

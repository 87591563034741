import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CONFIG from '../config.json';

const Contact = () => {
  useEffect(() => {
    document.title = `Contacto ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col className='text-center text-uppercase mb-3' xs={12} md={6}>
          <Link
            className='text-decoration-none text-black'
            to='/contacto/venta'
          >
            <Image
              className='mb-3'
              src='/img/contact/seller.png'
              fluid={true}
            />
            <p className='h3 no-bold'>Venta de equipos</p>
          </Link>
        </Col>
        <Col className='text-center text-uppercase' xs={12} md={6}>
          <Link
            className='text-decoration-none text-black'
            to='/contacto/garantia'
          >
            <Image
              className='mb-3'
              src='/img/contact/warranty.png'
              fluid={true}
            />
            <p className='h3 no-bold'>Garantía y venta de refacciones</p>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;

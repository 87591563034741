import { useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';

import CONFIG from '../config.json';

const PickUs = () => {
  useEffect(() => {
    document.title = `Informate bien y elige el mejor ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='d-flex flex-column py-5'>
      <Image className='mb-3' src='/img/pick-us/banner.jpg' fluid={true} />
      <Image
        className='mb-3'
        fluid={true}
        src='/img/pick-us/table-mirage.jpg'
      />
      <Image
        className='mb-3'
        fluid={true}
        src='/img/pick-us/table-benelux.jpg'
      />
      <Image
        className='mb-3'
        fluid={true}
        src='/img/pick-us/table-hisense.jpg'
      />
      <Image fluid={true} src='/img/pick-us/table-mabe.jpg' />
    </Container>
  );
};

export default PickUs;

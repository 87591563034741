import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import CONFIG from '../config.json';

const Inverter = () => {
  useEffect(() => {
    document.title = `Tecnología Inverter ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <div className='d-flex justify-content-center align-items-center'>
        <h3 className='no-bold pr'>
          Todo lo que debes de saber sobre la tecnología
        </h3>
        <Image src='/img/inverter/logo.png' />
      </div>
      <Row>
        <Col className='d-flex align-items-center' xs={12} md={5}>
          <Image
            alt='Razones para instalar Aires Prime'
            fluid={true}
            src='/img/inverter/banner.png'
          />
        </Col>
        <Col className='py-3 py-md-0' xs={12} md={7}>
          <h4 className='text-uppercase'>¿Qué es un equipo inverter?</h4>
          <p>
            Un minisplit inverter es un equipo dotado con una tecnología que
            permite al compresor variar la potencia de acuerdo a las necesidades
            del usuario. Entre más se acerca la temperatura de la habitación a
            aquella temperatura requerida por el usuario, menor es la potencia
            utilizada por el compresor para continuar enfriando la habitación.
          </p>
          <h4 className='text-uppercase'>Cómo funciona un equipo inverter?</h4>
          <p>
            El funcionamiento de un equipo regular es básicamente de prendido y
            apagado; es decir, un equipo normal utiliza toda su capacidad de
            enfriamiento hasta llegar a la temperatura deseada en la habitación,
            y es en ese momento cuando el sistema apaga el compresor. Una vez
            que la temperatura de la habitación vuelve a subir, el compresor
            arranca nuevamente a su máxima capacidad. El funcionamiento de un
            equipo inverter es gradual; conforme la temperatura actual de la
            habitación se acerca a la temperatura deseada, el compresor gradúa
            la potencia utilizada, generando un <b>ahorro de energía</b> al no
            estar operando a su máxima capacidad.
          </p>
        </Col>
      </Row>
      <Row className='py-4'>
        <Col
          className='d-flex justify-content-center align-items-center mb-3'
          xs={12}
          lg={6}
        >
          <Image src='/img/inverter/regular-consumption.jpg' fluid={true} />
        </Col>
        <Col
          className='d-flex justify-content-center align-items-center mb-3'
          xs={12}
          lg={6}
        >
          <Image src='/img/inverter/inverter-consumption.jpg' fluid={true} />
        </Col>
        <Col
          className='d-flex justify-content-center align-items-center'
          xs={12}
        >
          <Image src='/img/inverter/savings.jpg' fluid={true} />
        </Col>
      </Row>
      <div>
        <h4 className='text-uppercase'>
          Para aprovechar las ventajas de un equipo inverter es necesario:
        </h4>
        <ol>
          <li>Instalar la capacidad correcta de acuerdo al área a enfriar</li>
          <li>
            Tener un uso prolongado de 5 horas o más diarias para absorver la
            inversión en el menor tiempo posible
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-uppercase'>
          ¿Siempre es conveniente instalar un equipo inverter?
        </h4>
        <p>
          Aunque los equipos inverter tienen muchas ventajas en cuanto a
          operación y consumo de energía, es importante hacer notar que no
          siempre son la opción adecuada. Existen ocasiones en que los equipos
          regulares resultan ser una mejor opción que los inverter, por ejemplo,
          cuando se pretende instalar un equipo con una capacidad menor a la
          necesaria sólo para “refrescar” el área. En este caso, ni el equipo
          inverter ni el regular alcanzarán la temperatura ideal de 25º C,
          además de que ambos equipos estarán trabajando al máximo de su
          capacidad durante el tiempo de operación. Por este motivo resulta
          innecesario gastar más dinero en la compra de un equipo de tecnología
          inverter, ya que no se producirá ahorro alguno. Otro ejemplo es cuando
          el área específica a enfriar no se utiliza mucho, como podría ser un
          área social, ya que en muchos casos sólo se utiliza durante 3 ó 4
          horas mensualmente, por lo que, para recuperar la inversión del equipo
          inverter mediante el ahorro en el consumo de energía, se requerirían
          muchos meses. Para considerar la instalación de un equipo inverter en
          una habitación se recomienda:
        </p>
        <ol>
          <li>
            Instalar un equipo con capacidad suficiente para el área requerida
          </li>
          <li>
            Periodos de uso constante y prolongados (8 a 24 horas diarias
            aproximadamente). Algunas áreas sugeridas son oficinas, cavas de
            vino, cuartos de computadoras, restaurantes, habitaciones para
            dormir, etc
          </li>
        </ol>
      </div>
      <div>
        <h4 className='text-uppercase'>
          ¿Cuál es el ahorro aproximado de un equipo inverter y en cuanto tiempo
          recupero mi inversión?
        </h4>
        <p>
          El consumo se calcula de la siguiente forma: (amperaje x voltaje x
          factor de uso) / 1000 x horas uso al día x 30 días del mes x tarifa
          eléctrica DAC
        </p>
      </div>
      <Image
        className='mb-3'
        src='/img/inverter/approximate-savings.png'
        fluid={true}
      />
      <div>
        <h4 className='text-uppercase'>
          ¿Es más conveniente un equipo con mayor eficiencia SEER?. No
          necesariamente
        </h4>
        <p>
          Nuestros equipos cuentan con eficiencia SEER 16, mientras que existen
          otros con eficiencia SEER 21, y aunque éstos son más eficientes, la
          realidad es que recuperar tu inversión puede tomar 5 años o más debido
          al diferencial de precios existente entre ambos equipos. En base a
          investigación, en PRIME hemos determindo que la mejor combinación para
          usos y costumbres de nuestro país es un equipo con eficiencia SEER 16.
        </p>
      </div>
      <div>
        <h4 className='text-uppercase'>Ejemplo SEER 21 VS SEER 16</h4>
        <p>
          Equipo de 1 tonelada (12,000 BTU/hr). Consumos aproximados en la zona
          sur y peninsular del país.
        </p>
        <p>
          Diferecia aproximada de precio entre ambos: $4,000 pesos <br />
          Consumo aproximado mensual de un SEER 21: $212 pesos <br />
          Consumo aproximado mensual de un SEER 16: $275 pesos <br />
          Ahorro aproximado mensual SEER 21 vs SEER 16: $63 pesos
        </p>
        <p>
          Tiempo para absorver el diferencial del sobrecosto con el ahorro
          ($4,000 pesos /$63 pesos mensuales)=63 meses y medio (más de 5 años)
        </p>
      </div>
      <h4 className='text-uppercase'>
        ¿Por qué debo adquirir un equipo Inverter Prime?
      </h4>
      <Image src='/img/inverter/inverter-information.png' fluid={true} />
    </Container>
  );
};

export default Inverter;

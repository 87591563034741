import { useEffect } from 'react';
import { Container, Carousel, Image, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faChartColumn,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';

import CONFIG from '../config.json';

const Home = () => {
  useEffect(() => {
    document.title = `Inicio ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <>
      {/* Main carousel */}
      <Container fluid='md'>
        <Carousel fade={true} indicators={false}>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-1.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-2.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-3.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-4.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-5.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-6.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-7.jpg' fluid={true} />
          </Carousel.Item>
          <Carousel.Item>
            <Image src='/img/carousel/carousel-8.jpg' fluid={true} />
          </Carousel.Item>
        </Carousel>
      </Container>
      {/* Cards */}
      <Container className='info-container py-5'>
        <Row>
          <Col className='mb-3 mb-lg-0' xs={12} md={6} lg={4}>
            <div className='card rounded-0 d-flex flex-column bg-white p-3 text-center shadow h-100'>
              <FontAwesomeIcon icon={faHouse} size='3x' />
              <p className='h4 py-3'>
                ¿Por qué debo instalar Prime en mi casa u oficina?
              </p>
              <Link to='/porque' className='mt-auto d-flex'>
                <Button className='rounded-0 text-uppercase w-100'>
                  Ver más
                </Button>
              </Link>
            </div>
          </Col>
          <Col className='mb-3 mb-lg-0' xs={12} md={6} lg={4}>
            <div className='card rounded-0 d-flex flex-column bg-white p-3 text-center shadow h-100'>
              <FontAwesomeIcon icon={faChartColumn} size='3x' />
              <p className='h4 py-3'>
                Ventajas de convertirme en distribuidor Prime
              </p>
              <Link to='/ventajas' className='mt-auto d-flex'>
                <Button className='rounded-0 text-uppercase w-100'>
                  Ver ventajas
                </Button>
              </Link>
            </div>
          </Col>
          <Col className='mb-3 mb-lg-0' xs={12} md={6} lg={4}>
            <div className='card rounded-0 d-flex flex-column bg-white p-3 text-center shadow h-100'>
              <FontAwesomeIcon icon={faCircleQuestion} size='3x' />
              <p className='h4 py-3'>
                ¿Tienes dudas? <br /> Todo lo que tienes que saber sobre la
                tecnología Inverter
              </p>
              <Link to='/saber' className='mt-auto d-flex'>
                <Button className='rounded-0 text-uppercase w-100'>
                  Ver más
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Information */}
      <div className='bg-white text-center py-5 px-2'>
        <Container>
          <h3 className='text-uppercase no-bold'>
            ¿Por qué debo de adquirir un equipo Inverter Prime?
          </h3>
          <p>
            Prime te ofrece equipos de alta tecnología con diseños modernos,
            funcionamiento silencioso y la MEJOR garantía en el mercado
            mexicano. <br /> Además contamos con más de 40 centros de servicio
            en todo el país y una amplia existencia de partes y refacciones
          </p>
          <Link to='/saber'>
            <Button className='rounded-0 text-uppercase'>Leer más</Button>
          </Link>
        </Container>
      </div>
      {/* Badges */}
      <Container>
        <Row className='py-5'>
          <Col xs={12} md={6} lg={4} className='text-center'>
            <p className='h4 no-bold border-bottom text-uppercase pb-2'>
              Garantía
            </p>
            <Image
              src='/img/badges/warranty.png'
              alt='La mejor garantía del mercado'
              fluid={true}
            />
            <p>
              La garantía es nuestro compromiso a largo plazo con todos nuestros
              clientes y usuarios. Por tal motivo, PRIME te ofrece LA MEJOR
              GARANTÍA DEL MERCADO.
            </p>
          </Col>
          <Col xs={12} md={6} lg={4} className='text-center'>
            <p className='h4 no-bold border-bottom text-center text-uppercase pb-2'>
              Ahorro
            </p>
            <Image
              src='/img/badges/energy.png'
              alt='Ahorro energético'
              fluid={true}
            />
            <p>
              Equipos de alta eficiencia y tecnología que proporcionan un máximo
              ahorro.
            </p>
          </Col>
          <Col xs={12} md={12} lg={4} className='text-center'>
            <p className='h4 no-bold border-bottom text-center text-uppercase pb-2'>
              Centros de servicio
            </p>
            <Image
              src='/img/badges/service.png'
              alt='Centros de servicios'
              fluid={true}
            />
            <p>
              Más de 40 centros de servicio en las principales ciudades del país
              y una red de técnicos autorizados afiliados que ofrecen
              profesionalismo en cada trabajo realizado.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;

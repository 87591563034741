import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import CONFIG from '../config.json';

const TelevisionVideo = () => {
  useEffect(() => {
    document.title = `Comercial de tv ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='d-flex py-5'>
      <video controls={true}>
        <source src='/videos/prime-tv.mp4' type='video/mp4' />
      </video>
    </Container>
  );
};

export default TelevisionVideo;

import { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import CONFIG from '../config.json';

const Usage = () => {
  useEffect(() => {
    document.title = `Consejos prácticos para el uso eficiente de los equipos de aire acondicionado ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col className='order-1 order-md-0' xs={12} md={6}>
          <h2 className='mt-3 mt-md-0'>
            Consejos prácticos para el uso eficiente de los equipos de aire
            acondicionado
          </h2>
          <p className='text-uppercase text-primary h5 py-3'>
            Guía para reducir el consumo energético de los aires acondicionados:
          </p>
          <ol type='1'>
            <li>
              Usar el aparato a 24 ó 25 grados centígrados. Al poner una
              temperatura menor, el aire de su equipo no saldrá más frío, y lo
              único que estará logrando es que su equipo trabaje más tiempo del
              requerido sin cortar el compresor, lo que ocasionará que la vida
              de éste se reduzca
            </li>
            <li>
              Se recomienda ampliamente el uso de un ventilador como apoyo al
              aire acondicionado. Esto no reducirá la temperatura de la
              habitación pero sí creará una mejor sensación térmica
            </li>
            <li>
              Considerar 13 metros cuadrados por tonelada para ciudades con
              temperaturas que excedan los 38 grados centígrados durante gran
              parte de la temporada de calor
            </li>
            <li>
              Asegúrese de que el área esté completamente cerrada. Cualquier
              abertura existente ocasionará que el equipo trabaje
              inapropiadamente
            </li>
            <li>
              Utilice la función SLEEP durante las noches, esto hará que el
              equipo no enfríe tanto cuando ya no se requiere, dado que al
              dormir el cuerpo ya no produce tanto calor y no necesita de más
              frío para sentirse confortable
            </li>
            <li>
              Limpiar los filtros regularmente, de preferencia cada 15 días, con
              el fin de no obstruir el volúmen de aire dentro del equipo
            </li>
            <li>
              Asegurarse de darle mantenimiento al equipo cada 6 meses, o al
              menos cada año, con el fin de detectar que la carga del gas
              refrigerante sea la adecuada para que su equipo siga funcionando
              de la mejor manera
            </li>
          </ol>
        </Col>
        <Col className='order-0 order-md-1' xs={12} md={6}>
          <Image src='/img/news/use-tips-banner.png' fluid={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default Usage;

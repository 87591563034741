import { useEffect } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CONFIG from '../config.json';

const News = () => {
  useEffect(() => {
    document.title = `Noticias y comunicados ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/video-institucional'
          >
            <Image src='/img/news/video-banner.png' fluid={true} />
            <p>Video institucional</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/comercial-cine'
          >
            <Image src='/img/news/theater-banner.png' fluid={true} />
            <p>Comercial de cine</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/comercial-tv'
          >
            <Image src='/img/news/tv-banner.png' fluid={true} />
            <p>Comercial para televisión</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/porque'
          >
            <Image src='/img/news/reasons-banner.png' fluid={true} />
            <p>¿Por qué debo de instalar Aires Prime en mi casa u oficina?</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/consejos-seleccion'
          >
            <Image src='/img/news/selection-tips-banner.png' fluid={true} />
            <p>Consejos prácticos para la selección del aire acondicionado</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/consejos-uso'
          >
            <Image src='/img/news/use-tips-banner.png' fluid={true} />
            <p>
              Consejos prácticos para el uso eficiente del aire acondicionado
            </p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/tecnologia'
          >
            <Image src='/img/news/technology-banner.png' fluid={true} />
            <p>Tecnología Prime</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/saber'
          >
            <Image src='/img/news/inverter-banner.png' fluid={true} />
            <p>Todo lo que tienes que saber sobre la tecnología Inverter</p>
          </Link>
        </Col>
        <Col
          className='d-flex align-items-center flex-column'
          xs={12}
          md={6}
          lg={4}
        >
          <Link
            className='text-decoration-none text-black text-center'
            to='/ventajas'
          >
            <Image src='/img/news/benefits-banner.png' fluid={true} />
            <p>Ventajas de convertirme en distribuidos Prime</p>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default News;

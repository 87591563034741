import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const FloatButton = () => {
  return (
    <a
      className='d-flex justify-content-center align-items-center float-button'
      href='https://wa.me/3314676430'
    >
      <FontAwesomeIcon icon={faWhatsapp} size='4x' color='#73C566' />
    </a>
  );
};

export default FloatButton;

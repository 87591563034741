import { Col, Container, Image, Row } from 'react-bootstrap';

const Information = () => {
  return (
    <Container className='py-5'>
      <Row>
        <Col className='d-flex justify-content-start mb-3' xs={12} md={6}>
          <Image src='/img/us/us-2.png' fluid={true} />
        </Col>
        <Col xs={12} md={6}>
          <p>
            En PRIME no concebimos otra forma de lograr el éxito si no es a
            través de la construcción de una relación a largo plazo con nuestros
            distintos clientes.
          </p>
          <p>
            El diseño de una estrategia de ganar-ganar permitirá a ambas partes
            el compromiso y la confianza necesarios para poder enfocar los
            esfuerzos conjuntos en la meta común de lograr el crecimiento de
            ambos.
          </p>
          <p>
            Es a través de la honestidad, responsabilidad y compromiso que PRIME
            ha logrado ser marca líder con alta presencia en el mercado
            mexicano.
          </p>
        </Col>
      </Row>
      <h3 className='no-bold text-uppercase text-primary mb-4'>
        Información de la compañía
      </h3>
      <Row>
        <Col xs={12} md={6}>
          <h4 className='no-bold text-uppercase text-primary'>Historia</h4>
          <p>
            Prime de México nace en el año 2001 con la firma intención de
            introducir al mercado una oferta integral producto/comercial que sea
            la más favorable para el mercado mexicano.
          </p>
          <p>
            Nuestra empresa está integrada por personas con más de 15 años de
            experiencia en el ramo del aire acondicionado, lo que nos ha
            permitido integrarnos de manera rápida a las necesidades de nuestros
            clientes, permitiéndonos lograr crecimientos importantes de más del
            50% cada año.
          </p>
          <p>
            Actualmente, tenemos presencia en prácticamente todo el país,
            contamos con más de 160 distribuidores a nivel nacional y hemos
            edificado una red de centros de servicio en más de 60 ciudades de la
            República Mexicana.Lo anterior ha traído como resultado que PRIME de
            México sea la tercer empresa con mayores ventas de minisplits en
            nuestro país.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <h4 className='no-bold text-uppercase text-primary'>Misión</h4>
              <p>
                Nuestra misión es entregar al mercado mexicano los equipos
                minisplit con la mejor relación precio-calidad que exista en el
                mercado.
              </p>
            </Col>
            <Col xs={12}>
              <h4 className='no-bold text-uppercase text-primary'>Visión</h4>
              <p>
                Confiamos que, emulando el desempeño que nos ha caracterizado en
                nuestros primeros 10 años, podremos convertirnos en una de las
                empresas líderes en distribución y comercialización de equipos
                minisplit a nivel nacional.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Information;

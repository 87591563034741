import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';

import CONFIG from '../config.json';

import products from '../data/products.json';

const Product = () => {
  const { id } = useParams();

  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    const foundProduct = products.find((item) => item.id === id);

    if (foundProduct) {
      setProduct(foundProduct);
    } else {
      setNotFound(true);
    }
  }, [id]);

  useEffect(() => {
    if (product.name) {
      document.title = `Producto: ${product.name} ${CONFIG.TITLE_SUFFIX}`;
    }
  }, [product]);

  if (notFound) {
    return (
      <div className='d-flex h3 text-center py-5 text-uppercase flex-column'>
        <p>El producto no fue encontrado</p>
        <FontAwesomeIcon className='mb-3' icon={faFaceFrown} size='4x' />
        <Link to='/productos'>
          <Button className='rounded-0 text-uppercase'>
            Regresar a productos
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <Container className='d-flex p-5 flex-column'>
      <Image
        alt={`Header ${product.name}`}
        className='mb-3'
        fluid={true}
        src={`/img/products/headers/${product.id}.jpg`}
      />
      <Image
        alt={`Información de ${product.name}`}
        className='mb-3'
        fluid={true}
        src={`/img/products/about/${product.id}.jpg`}
      />
      <Image
        alt={`Especificaciones de ${product.name}`}
        className='mb-3'
        fluid={true}
        src={`/img/products/specs/${product.id}.jpg`}
      />
      <a className='align-self-center' href={`/booklets/${product.id}.pdf`}>
        <Button className='text-uppercase rounded-0'>
          Descargar folleto en PDF
        </Button>
      </a>
    </Container>
  );
};

export default Product;

import { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import CONFIG from '../config.json';

const Selection = () => {
  useEffect(() => {
    document.title = `Consejos prácticos para la selección del aire acondicionado ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col className='order-1 order-md-0' xs={12} md={6}>
          <h2 className='mt-3 mt-md-0'>
            Consejos prácticos para la selección del aire acondicionado
          </h2>
          <p className='text-uppercase text-primary h5 py-3'>
            Consejos para elegir adecuadamente un equipo:
          </p>
          <p>
            La selección del equipo adecuado para enfriar un área es muy
            importante, ya que de esta manera se estará asegurando que el nivel
            de confort, así como el funcionamiento del equipo, sea el más
            adecuado. Instalar un equipo con capacidad de enfriamiento y/o
            calentamiento más alta o más baja de lo que se requiere, ocasionará
            daños importantes en su aparato, mayor consumo energético y
            alteraciones al confort deseado.
          </p>
          <p>
            Sabemos que el precio juega un papel muy importante en la decisión
            de compra de cada consumidor; por tal motivo, Prime mantiene una
            política de precios muy agresiva junto a sus canales de
            distribución, logrando ser siempre una de las más competitivas del
            mercado.
          </p>
          <p>
            Cabe señalar que cada caso en particular es distinto y que el
            proceso de selección de su equipo puede variar de acuerdo a las
            particularidades del área que usted desea acondicionar. Estos son
            únicamente algunos consejos. Se recomienda acudir con un técnico
            especializado que revise personalmente su área.
          </p>
          <ol type='A'>
            <li>
              Considerar 20 m2 por cada 12,000 btu/h (1 tonelada) para ciudades
              que tengan una temperatura máxima de 26 a 30 grados centígrados
              durante el verano
            </li>
            <li>
              Considerar 16 m2 por cada 12,000 btu/h (1 ton) para ciudades que
              tengan una temperatura de entre 30 y 38 grados centígrados
            </li>
            <li>
              Considerar 13 m2 por tonelada para ciudades cuyas temperaturas
              excedan los 38 grados centígrados durante gran parte de la
              temporada de calor
            </li>
            <li>
              Si existen en promedio más de 2 personas en el área a
              acondicionar, agregar a la capacidad necesaria 500 btu/h por cada
              persona adicional que se encuentre en el área
            </li>
            <li>Si existe alguna parrilla o estufa, agregar 5000 btu/h</li>
          </ol>
          <p className='mb-0'>
            Supuestos: 40% de humedad, techo con 3 m. de altura, 2 personas por
            habitación, áreas totalmente cerradas.
          </p>
        </Col>
        <Col className='order-0 order-md-1' xs={12} md={6}>
          <Image src='/img/news/selection-tips-banner.png' fluid={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default Selection;

import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CONFIG from '../config.json';

import products from '../data/products.json';

const Products = () => {
  useEffect(() => {
    document.title = `Productos ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='pb-5'>
      <h3 className='no-bold pt-5 pb-3 text-uppercase'>Nuestros productos</h3>
      <Row>
        {products.map((item) => {
          return (
            <Col
              key={`product-${item.id}`}
              className='d-flex mb-3'
              xs={12}
              md={6}
              lg={4}
            >
              <Link className='d-flex w-100' to={`/producto/${item.id}`}>
                <Image
                  alt={item.name}
                  fluid={true}
                  src={`/img/products/listing/${item.id}.jpg`}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Products;

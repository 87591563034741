import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatButton from './components/FloatButton';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import Us from './pages/Us';
import Products from './pages/Products';
import Product from './pages/Product';
import Contact from './pages/Contact';
import PickUs from './pages/PickUs';
import Information from './pages/Information';
import Why from './pages/Why';
import ContactForm from './pages/ContactForm';
import Inverter from './pages/Inverter';
import Benefits from './pages/Benefits';
import News from './pages/News';
import Selection from './pages/Selection';
import Usage from './pages/Usage';
import InstitutionalVideo from './pages/InstitutionalVideo';
import TheaterVideo from './pages/TheaterVideo';
import TelevisionVideo from './pages/TelevisionVideo';
import Technology from './pages/Technology';

const App = () => {
  return (
    <Router>
      <Header />
      <Navbar />

      <ScrollToTop>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/nosotros' element={<Us />} />
          <Route path='/productos' element={<Products />} />
          <Route path='/producto/:id' element={<Product />} />
          <Route path='/contacto' element={<Contact />} />
          <Route path='/elige-bien' element={<PickUs />} />
          <Route path='/informacion' element={<Information />} />
          <Route path='/porque' element={<Why />} />
          <Route path='/contacto/venta' element={<ContactForm />} />
          <Route path='/contacto/garantia' element={<ContactForm />} />
          <Route path='/saber' element={<Inverter />} />
          <Route path='/ventajas' element={<Benefits />} />
          <Route path='/noticias' element={<News />} />
          <Route path='/consejos-seleccion' element={<Selection />} />
          <Route path='/consejos-uso' element={<Usage />} />
          <Route path='/video-institucional' element={<InstitutionalVideo />} />
          <Route path='/comercial-cine' element={<TheaterVideo />} />
          <Route path='/comercial-tv' element={<TelevisionVideo />} />
          <Route path='/tecnologia' element={<Technology />} />
        </Routes>
      </ScrollToTop>

      <FloatButton />

      <Footer />
    </Router>
  );
};

export default App;

import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import CONFIG from '../config.json';

const Why = () => {
  useEffect(() => {
    document.title = `Razones para instalar Aires Prime ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col className='d-flex align-items-center' xs={12} md={5}>
          <Image
            alt='Razones para instalar Aires Prime'
            fluid={true}
            src='/img/why/banner.png'
          />
        </Col>
        <Col className='py-3 py-md-0' xs={12} md={7}>
          <Row>
            <Col
              className='d-flex justify-content-center align-items-center'
              xs={4}
            >
              <Image src='/img/why/quality-badge.png' fluid={true} />
            </Col>
            <Col xs={8}>
              <h3 className='no-bold text-uppercase text-primary'>
                Garantía y calidad
              </h3>
              <p>
                garantía es nuestro compromiso a largo plazo con nuestros
                usuarios. Por tal motivo, Aires Prime ofrece:
              </p>
            </Col>
          </Row>
          <div className='py-3'>
            <ul>
              <li>La mejor garantía del mercado</li>
              <li>No exige requisitos incumplibles para otorgar la garantía</li>
              <li>
                Una póliza de garantía diseñada en conjunto con el personal de
                PROFECO, siendo ésta una póliza justa y equitativa
              </li>
              <li>
                Productos de alta calidad, gracias a avanzados y estrictos
                estándares tecnológicos y de calidad
              </li>
              <li>
                Equipos certificados en distintos programas gubernamentales,
                tales como ASI, PNSEE, HIPOTECA VERDE, etc
              </li>
              <li>
                Flexibilidad en garantía. Al momento de solicitar la garantía de
                su equipo, una llamada en la que nos proporciona unos datos muy
                sencillos es suficiente para brindarle un servicio rápido
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <h2 className='text-center text-uppercase py-3'>
        ¿Por qué debo instalar Aires Prime en mi casa u oficina?
      </h2>
      <Row>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/why/price-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>Precio</h3>
              <p>
                Sabemos que el precio juega un papel muy importante en la
                decisión de compra de cada consumidor; por tal motivo, Prime
                mantiene una política de precios muy agresiva junto a sus
                canales de distribución, logrando ser siempre una de las más
                competitivas del mercado.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/why/service-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Centros de servicio
              </h3>
              <p>
                Más de 40 centros de servicio en las principales ciudades del
                país, y una red de técnicos autorizados afiliados en cada una de
                las ciudades donde se comercializan nuestros equipos.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/why/technology-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Tecnología y funcionalidad
              </h3>
              <p>
                Todos los equipos Prime cuentan con una avanzada tecnología y
                las funciones más innovadoras e importantes para ofrecer el
                máximo confort y aprovechamiento del equipo.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/why/assist-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>Asesoría</h3>
              <p>
                La correcta elección del equipo de aire acondicionado es muy
                importante; por tal motivo, Prime siempre está dispuesto a
                resolver todas las dudas relacionadas con la elección de la
                capacidad y el tipo de equipo más conveniente de acuerdo a sus
                necesidades.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Why;

import { useState } from 'react';
import { Image } from 'react-bootstrap';

const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());

  return (
    <div className='text-center text-uppercase px-2'>
      <p className='mb-0'>
        Copyright © Aires Prime 2017 - {currentYear} | Todos los derechos
        reservados
      </p>
      <p>
        <a href='mailto:ventas@airesprime.com'>ventas@airesprime.com</a>
      </p>

      <Image src='/img/logo.png' />
    </div>
  );
};

export default Footer;

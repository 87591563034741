import { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import CONFIG from '../config.json';

const Benefits = () => {
  useEffect(() => {
    document.title = `Ventajas ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  return (
    <Container className='py-5'>
      <Row>
        <Col className='d-flex align-items-center' xs={12} md={5}>
          <Image
            alt='Razones para instalar Aires Prime'
            fluid={true}
            src='/img/benefits/banner.png'
          />
        </Col>
        <Col className='py-3 py-md-0' xs={12} md={7}>
          <Row>
            <Col
              className='d-flex justify-content-center align-items-center'
              xs={4}
            >
              <Image src='/img/benefits/relation-badge.png' fluid={true} />
            </Col>
            <Col xs={8}>
              <h3 className='no-bold text-uppercase text-primary'>
                Relación a largo plazo
              </h3>
              <p>
                En PRIME no concebimos otra forma de lograr el éxito si no es a
                través de la construcción de una relación a largo plazo con
                nuestros clientes. El diseño de una estrategia ganar-ganar
                permitirá a ambas partes el compromiso y la confianza necesarios
                para poder enfocar los esfuerzos conjuntos en la meta común de
                lograr el crecimiento de ambos. <br /> Es a través de la
                honestidad, responsabilidad y compromiso que PRIME ha logrado
                ser marca líder con alta presencia en el mercado mexicano.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='pt-4'>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/grow-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Crecimiento
              </h3>
              <p>
                Actualmente, la marca PRIME se sostiene como el tercer proveedor
                más importante de equipos minisplit en el país y como la empresa
                con mayor crecimiento en los últimos 5 años. Su crecimiento ha
                sido notorio en la industria y gracias a distintas estrategias
                implementadas, somos constante referente entre nuestros
                distintos competidores. <br />
                Sin embargo, la penetración lograda hasta este momento así como
                su participación de mercado,ofrece oportunidades de crecimiento
                sumamente importantes para nuestros distribuidores; y más aún,
                oportunidades de ser una opción rentable. Cada vez estamos en
                más ciudades y el reconocimiento de la marca PRIME continúa en
                un marcado ascenso.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/price-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Precio y condiciones comerciales
              </h3>
              <p>
                Nuestros precios y condiciones comerciales son de los más
                competitivos en el mercado. Junto con nuestros distintos
                distribuidores, diseñamos un plan que se adapte de la mejor
                manera a las necesidades propias de su negocio y a los objetivos
                comerciales de nuestra empresa.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/price-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Protección territorial y de canal
              </h3>
              <p>
                En PRIME nos preocupamos por no cometer el mismo error de la
                mayoría de los competidores al saturar con muchos distribuidores
                el mercado, a tal grado que el negocio deje de ser interesante.
                Realizamos estudios de mercado y de canal enfocados a cada uno
                de nuestros clientes para determinar el número y el perfil del
                distribuidor necesario para cada una de las zonas; además, somos
                pacientes con nuestros distribuidores al darles el tiempo
                suficiente para el desarrollo de su propio mercado.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/price-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Calidad, garantía y características
              </h3>
              <p>
                La fabricación de los equipos marca PRIME se lleva a cabo en
                convenio con las más prestigiadas fábricas a nivel mundial,
                ofreciendo características similares o superiores a muchas de
                las marcas "líderes" en la industria. Por tal motivo, desde el
                nacimiento de nuestra marca,PRIME ha ofrecido al público en
                general la mejor garantía en sus productos. Esta es una
                herramienta sumamente poderosa que puede ser utilizada por
                nuestros distribuidores para la venta de nuestros productos con
                mejores márgenes de ganancias.Los equipos PRIME cuentan con
                todas las funciones importantes para el usuario final y tienen
                características positivas en relación al ahorro de energía,
                calidad de compresores, etc. Nuestros equipos están certificados
                en los distintos programas gubernamentales como ASI, PNSEE,
                HIPOTECA VERDE, etc. que ayudan a tener muchas más oportunidades
                de comercialización que nuestros competidores.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/strategy-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Estrategia única PRIME-Distribuidor
              </h3>
              <p>
                A diferencia de nuestros competidores, quizá la mayor distinción
                es que nosotros no tratamos a nuestros clientes pensando en que
                todos ellos son iguales. Estamos conscientes de las diferencias
                entre canales, mercados y clientes, y en conjunto desarrollamos
                una estrategiaúnica diseñada para un distribuidor en específico
                para poder explotar al máximo las ventajas de ambos. Es a través
                de la comunicación y el intercambio de información de mercado
                que se puede llegar al desarrollo de una estrategia ideal para
                poder lograr el máximo crecimiento posible.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/service-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Centros de servicio
              </h3>
              <p>
                Contamos con más de 40 centros de servicio en las principales
                ciudades del país, así como una red de técnicos autorizados
                afiliados en ciudades y poblaciones medianas y chicas a todo lo
                largo y ancho del territorio mexicano.
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image src='/img/benefits/service-badge.png' fluid={true} />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Refacciones y servicios
              </h3>
              <p>
                Aunque en este rubro nunca se puede dejar de mejorar para poder
                lograr una mayor satisfacción tanto de clientes finales como de
                distribuidores, es un hecho que el servicio post-venta y la
                existencia de refacciones de PRIME se encuentra entre las
                mejores a nivel nacional, comparado con otras marcas líderes en
                el mercado. <br />
                En PRIME contamos:
                <ul>
                  <li>Precios competitivos en refacciones</li>
                  <li>Rapidez en la entrega</li>
                  <li>
                    Alta existencia de refacciones y partes para modelos en
                    existencia y modelos anteriores, etc
                  </li>
                </ul>
              </p>
            </Col>
          </Row>
        </Col>
        <Col className='mb-3 mb-lg-0' xs={12} lg={6}>
          <Row>
            <Col xs={3}>
              <Image
                src='/img/benefits/additional-services-badge.png'
                fluid={true}
              />
            </Col>
            <Col xs={9}>
              <h3 className='no-bold text-uppercase text-primary'>
                Servicios adicionales
              </h3>
              <p>
                Para los clientes que así lo requieran, Aires Prime pone a su
                disposición servicios adicionales como: Asesoría ante casos de
                PROFECO, negociación de tarifas de paquetería, servicios de
                diseño gráfico, apoyo en asesoría fiscal y otros más.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Benefits;

import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import CONFIG from '../config.json';

const ContactForm = () => {
  useEffect(() => {
    document.title = `Formulario de contacto ${CONFIG.TITLE_SUFFIX}`;
  }, []);

  const [sending, setSending] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    message: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setSending(true);

    fetch(CONFIG.SEND_EMAIL_URL, {
      method: 'POST',
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        phone: data.phone,
        city: data.city,
        state: data.state,
        message: data.message,
      }),
    })
      .then(() => {
        setShowModal('success');
        // Reste modal data
        setData({
          name: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          message: '',
        });
      })
      .catch((error) => {
        console.log(error);

        setShowModal('error');
      })
      .finally(() => setSending(false));
  };

  return (
    <>
      {/* Toasts */}
      <div className='toast-container'>
        <Toast
          animation={true}
          autohide={true}
          delay={5000}
          onClose={() => setShowModal('')}
          show={showModal === 'success'}
        >
          <Toast.Header>
            <b>Correo enviado</b>
          </Toast.Header>
          <Toast.Body>El correo fue enviado correctamente</Toast.Body>
        </Toast>
        <Toast
          animation={true}
          autohide={true}
          delay={5000}
          onClose={() => setShowModal('')}
          show={showModal === 'error'}
        >
          <Toast.Header>
            <b>Error</b>
          </Toast.Header>
          <Toast.Body>
            Se generó un error al enviar el correo. Por favor intente más tarde
          </Toast.Body>
        </Toast>
      </div>
      {/* Content */}
      <Container className='py-5'>
        <Row>
          <Col xs={12} sm={5} md={4}>
            <h4 className='no-bold text-uppercase'>Donde nos encontramos</h4>
            <iframe
              allowFullScreen={true}
              className='w-100'
              height={400}
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.4540282350054!2d-100.37236348513495!3d25.656239919219665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bdece3290299%3A0x4830b4496fbbd079!2sCalz%20San%20Pedro%20105-Interior%20101%2C%20Del%20Valle%2C%2066220%20San%20Pedro%20Garza%20Garc%C3%ADa%2C%20Nuevo%20Le%C3%B3n!5e0!3m2!1ses-419!2smx!4v1654560742369!5m2!1ses-419!2smx'
              title='Localización'
            ></iframe>
          </Col>
          <Col xs={12} sm={7} md={8}>
            <h4 className='no-bold text-uppercase'>Formulario de contacto</h4>
            <Form onSubmit={handleSubmit} className='py-3'>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  onChange={(e) =>
                    setData({
                      ...data,
                      name: e.currentTarget.value,
                    })
                  }
                  value={data.name}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  type='email'
                  onChange={(e) =>
                    setData({
                      ...data,
                      email: e.currentTarget.value,
                    })
                  }
                  value={data.email}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  type='phone'
                  onChange={(e) =>
                    setData({
                      ...data,
                      phone: e.currentTarget.value,
                    })
                  }
                  value={data.phone}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Ciudad</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  onChange={(e) =>
                    setData({
                      ...data,
                      city: e.currentTarget.value,
                    })
                  }
                  value={data.city}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  onChange={(e) =>
                    setData({
                      ...data,
                      state: e.currentTarget.value,
                    })
                  }
                  value={data.state}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Asunto</Form.Label>
                <Form.Control
                  readOnly={sending}
                  required={true}
                  className='rounded-0'
                  as='textarea'
                  rows={4}
                  onChange={(e) =>
                    setData({
                      ...data,
                      message: e.currentTarget.value,
                    })
                  }
                  value={data.message}
                />
              </Form.Group>
              <Button
                className='mt-3 rounded-0'
                type='submit'
                disabled={sending}
              >
                {sending ? (
                  <>
                    <FontAwesomeIcon
                      className='pr'
                      icon={faSpinner}
                      spin={true}
                    />
                    Enviando...
                  </>
                ) : (
                  'Enviar'
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;
